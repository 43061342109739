export const strToUpper = (str: string) => {
  const letters: any = {
    i: 'İ',
    ş: 'Ş',
    ğ: 'Ğ',
    ü: 'Ü',
    ö: 'Ö',
    ç: 'Ç',
    ı: 'I',
  };
  str = str.replace(/(([iışğüçö]))+/g, (letter) => letters[letter]);
  return str.toUpperCase();
};

export const strToLower = (str: any) => {
  const letters: any = {
    İ: 'i',
    I: 'ı',
    Ş: 'ş',
    Ğ: 'ğ',
    Ü: 'ü',
    Ö: 'ö',
    Ç: 'ç',
  };
  str = str.replace(/(([İIŞĞÜÇÖ]))+/g, (letter: any) => letters[letter]);
  return str.toLowerCase();
};

export const getCleanNumber = (value: any) => {
  if (!value) {
    return '';
  }
  let str = value.toString();
  str = str.replace(/[a-z/A-Z/./,/\-/(/)/\s/]/g, '');
  return str;
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^0(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `(${match[1]}) ${match[2]} ${match[3]} ${match[4]}`;
  }

  return phoneNumberString;
};

export const unformatPhoneNumber = (phoneNumberString: string): string =>
  phoneNumberString.replace('+9', '').replace(/\s/g, '').replace('(', '').replace(')', '').replace('_', '');

const formatter = new Intl.NumberFormat('tr-TR', {
  // style: 'currency',
  // currency: 'TRY',
});

export const formatPrice = (price: number): string => {
  return formatter.format(price);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
