'use client';

import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Button } from '../../ui/button/button';
import { InnerContainer } from '../../ui/common/inner-container';
import Heading from '../../ui/heading';
import { useFormState } from 'react-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { POSTSubscribeAction } from './actions';
import { z } from 'zod';
import { emailSubsSchema } from './email-subs-schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { unformatPhoneNumber } from '@/src/utils/strings';
import InputField from '../../ui/react-hook-form/ui/input-field';
import { CheckIcon } from '../../ui/icons/icons';
import { RelLink } from '../../ui/rel-link';

type TEmailSubscription = {
  pageId: string | number | null;
};

export const EmailSubscription: React.FC<TEmailSubscription> = ({ pageId }) => {
  // const [state, formAction] = useFormState(POSTSubscribeAction, null);
  type emailSubsSchemaType = z.infer<typeof emailSubsSchema>;

  const methods = useForm<emailSubsSchemaType>({
    resolver: zodResolver(emailSubsSchema),
    mode: 'all',
  });

  const onSubmit = useCallback(async () => {
    const allValues = methods.getValues();
    const formDataObj: emailSubsSchemaType & { pageId: string } = {
      ...allValues,
      PhoneNumber: unformatPhoneNumber(allValues.PhoneNumber ?? '') ?? '',
      pageId: pageId?.toString() ?? '',
    };

    const response = await POSTSubscribeAction(null, formDataObj);

    // console.log(response);

    if (response.status === 400) {
      methods.setError('Email', {
        type: 'manual',
        message: response.errors?.[0].code,
      });
    }

    if (response.status == 200) {
      methods.reset();
      // methods.trigger();
    }
  }, [methods]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={'bg-purple404'}>
          <InnerContainer className="flex flex-col gap-6 py-8 md:py-10" data-testid="emailSubscription">
            <Heading tagType={'h3'} cls="text-white md:text-2xl text-xl">
              Avantajlı faiz oranlarını ve finans dünyası gelişmelerini takip et
            </Heading>

            <div className="hidden md:flex md:gap-4">
              <InputField
                label="Adın"
                type="text"
                placeholder="Örn. Çağrı"
                name="Firstname"
                data-testid="Firstname"
                required
                // overrideClassName="flex flex-col"
                overrideLabelClassName="text-white font-semibold"
                noPunc
                noDigit
                noPuncWithComma
                overrideErrorClassName="text-xs text-white md:text-sm"
              />
              <InputField
                label="Soyadınız"
                type="text"
                placeholder="Örn. Oğuz"
                name="Lastname"
                data-testid="Lastname"
                required
                // overrideClassName="flex flex-col"
                overrideLabelClassName="text-white font-semibold"
                noPunc
                noDigit
                noPuncWithComma
                overrideErrorClassName="text-xs text-white md:text-sm"
              />
              <InputField
                label="E-Posta adresiniz"
                type="text"
                placeholder="ornek@gmail.com"
                name="Email"
                overrideLabelClassName="text-white font-semibold"
                overrideErrorClassName="text-xs text-white md:text-sm"
                required
              />
            </div>

            <div className="flex flex-col gap-4 md:hidden">
              <div className="flex gap-4">
                <InputField
                  label="Adın"
                  type="text"
                  placeholder="Örn. Çağrı"
                  name="Firstname"
                  data-testid="Firstname"
                  required
                  // overrideClassName="flex flex-col"
                  overrideLabelClassName="text-white font-semibold"
                  overrideErrorClassName="text-xs text-white md:text-sm"
                  noPunc
                  noDigit
                  noPuncWithComma
                />
                <InputField
                  label="Soyadınız"
                  type="text"
                  placeholder="Örn. Oğuz"
                  name="Lastname"
                  data-testid="Lastname"
                  required
                  // overrideClassName="flex flex-col"
                  overrideLabelClassName="text-white font-semibold"
                  overrideErrorClassName="text-xs text-white md:text-sm"
                  noPunc
                  noDigit
                  noPuncWithComma
                />
              </div>
              <InputField
                label="E-Posta adresiniz"
                type="text"
                placeholder="ornek@gmail.com"
                name="Email"
                overrideLabelClassName="text-white font-semibold"
                overrideErrorClassName="text-xs text-white md:text-sm"
                required
              />
            </div>

            <div check-boxes className="flex flex-col gap-3 md:mt-[18px] md:px-[11px]">
              <div className="flex w-full items-start md:items-center">
                <Controller
                  control={methods.control}
                  name={'ExplicitConsent'}
                  render={({ field }) => (
                    <label className="flex cursor-pointer items-center justify-start">
                      <span
                        className={classNames('mr-3 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded', {
                          'bg-white': field.value,
                          'border-2 border-gray25': !field.value,
                        })}
                      >
                        {field.value && <CheckIcon width="16px" height="16px" className="text-purple500" />}
                      </span>
                      <input
                        type="checkbox"
                        className="fixed left-0 top-0 hidden opacity-0"
                        {...methods.register('ExplicitConsent')}
                      />
                    </label>
                  )}
                />

                <div>
                  <span className="inline-block text-xs text-white md:text-sm">
                    Kişisel verilerimin{' '}
                    <strong className="underline">
                      <RelLink href="/hakkimizda/acik-riza-metni" target={'_blank'}>
                        Açık Rıza Metni
                      </RelLink>
                    </strong>
                    {/* */}
                    ’nde belirlenen şartlarda işlenmesine izin veriyorum.
                  </span>
                </div>
              </div>

              <div className="flex w-full items-start md:items-center">
                <Controller
                  control={methods.control}
                  name={'ClarificationText'}
                  render={({ field }) => (
                    <label className="flex cursor-pointer items-center justify-start">
                      <span
                        className={classNames('mr-3 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded', {
                          'bg-white': field.value,
                          'border-2 border-gray25': !field.value,
                        })}
                      >
                        {field.value && <CheckIcon width="16px" height="16px" className="text-purple500" />}
                      </span>
                      <input
                        type="checkbox"
                        className="fixed left-0 top-0 hidden opacity-0"
                        {...methods.register('ClarificationText')}
                      />
                    </label>
                  )}
                />

                <div>
                  <span className="inline-block text-xs text-white md:text-sm">
                    <strong className="underline">
                      <RelLink href="/hakkimizda/aydinlatma-metni-musteri" target={'_blank'}>
                        Kişisel Verilerin Koruması Hakkında Bilgilendirme
                      </RelLink>
                    </strong>
                    {/* */}
                    ’yi okuduğumu ve kabul ettiğimi onaylıyorum.
                  </span>
                </div>
              </div>

              <div className="flex w-full items-start md:items-center">
                <Controller
                  control={methods.control}
                  name={'IsSubscribe'}
                  render={({ field }) => (
                    <label className="flex cursor-pointer">
                      <span
                        className={classNames('mr-3 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded', {
                          'bg-white': field.value,
                          'border-2 border-gray25': !field.value,
                        })}
                      >
                        {field.value && <CheckIcon width="16px" height="16px" className="text-purple500" />}
                      </span>
                      <input
                        type="checkbox"
                        className="fixed left-0 top-0 hidden opacity-0"
                        {...methods.register('IsSubscribe')}
                      />
                    </label>
                  )}
                />

                <div>
                  <span className="inline-block text-xs text-white md:text-sm">
                    Hangikredi.com&apos;dan özel indirimli banka kredileri ve fırsatlar hakkında bilgi almak istediğimi
                    onaylıyorum.
                  </span>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              className={classNames('h-[52px] w-full rounded-lg md:h-[56px] md:w-[256px]')}
              text="Ücretsiz abone ol"
              disabled={!methods.formState.isValid}
              subsButton
            />
          </InnerContainer>
        </form>
      </FormProvider>
    </>
  );
};
